import { Injectable } from '@angular/core';
import { GuidIdName, PublicEventsService } from '../../openapi';
import { IRealtime, StateManagerService } from '../state-manager.service';
import { UserContextService } from '../user-context.service';


type TVisitedEventStateManager = StateManagerService<GuidIdName, string, IRealtime<GuidIdName>, unknown, unknown, string, void>;

@Injectable()
export class VisitedEventsManagerService {
  private stateManager: TVisitedEventStateManager = new StateManagerService({
    equals: (a, b) => a.id === b.id,
    getKey: a => a.id,
    getItem$: null!,
    getList$: () => this.publicEventsService.publiceventsVisitedGet(),
    deleteApi: null!,
    createApi: null!,
    updateApi: null!,
    userContext: this.userContext,
    connection: 'visitedevents',
  });

  get handle() {
    return this.stateManager;
  }

  constructor(
    private userContext: UserContextService,
    private publicEventsService: PublicEventsService,
  ) { }
}
